import { useState } from "react";
import { cn } from "@/lib/utils";
import { Button, buttonVariants } from "@/components/ui/button";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { CircleAlert } from "lucide-react";
import { Icons } from "../brand/icons";

export function SendFirstFileStep({ user, updateUser }) {
  const flock = user.flocks.find((flock) => flock.id === user.active_flock_id);
  const [loading, setLoading] = useState(false);
  return (
    <>
      <h1 className="text-2xl md:text-4xl font-semibold mt-32 text-center">
        Send your first file
      </h1>
      <p className="text-muted-foreground max-w-[420px] text-center">
        Effortlessly upload documents through email. Send any attachment to{" "}
        <a
          className="hover:underline text-blue-600"
          href="mailto:upload@vault.starttapestry.com"
        >
          upload@vault.starttapestry.com
        </a>{" "}
        to automatically save it to your Tapestry.
      </p>
      <p className="text-sm text-muted-foreground text-center max-w-[420px]">
        Download our sample file and see how easy it is to upload it to your
        Tapestry. If you belong to multiple Tapestries then include your desired
        Tapestry name in the subject or make sure to include an associated user
        as cc'ed in the email.
      </p>
      <Button onClick={handleDownload} className="w-fit" variant="outline">
        Download Sample File
      </Button>
      <Alert className="max-w-[373px] bg-blue-200">
        <CircleAlert className="h-4 w-4" />
        <AlertTitle>Heads up!</AlertTitle>
        <AlertDescription>
          After sending you will receive a confirmation email with a link to
          your document in your Tapestry. If you do not receive a confirmation,
          check your junk mail and mark not spam.
        </AlertDescription>
      </Alert>
      <div className="flex justify-between gap-4 w-full max-w-[373px]">
        <a
          href={`mailto:upload@vault.starttapestry.com?subject=${flock.name}&body=${body}`}
          className={cn(buttonVariants({ variant: "outline" }), "w-fit")}
        >
          Open Mail
        </a>
        <Button
          disabled={loading}
          onClick={() => {
            setLoading(true);
            updateUser({ onboarding_step: 7 });
          }}
        >
          {loading && <Icons.spinner className="animate-spin mr-2 w-4 h-4" />}
          Next
        </Button>
      </div>
    </>
  );
}

const handleDownload = () => {
  const link = document.createElement("a");
  link.href = "/tapestry-sample.pdf"; // Path to the file in the public directory
  link.download = "tapestry-sample.pdf"; // Name of the file to be downloaded
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const body =
  "Attach%20your%20sample%20document%20to%20this%20email.%0A%0AMake%20sure%20the%20email%20that%20you%20are%20sending%20from%20matches%20the%20email%20that%20you%20use%20to%20login.";
