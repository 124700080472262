import { useState } from "react";
import axios from "@/axios";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import {
  Form,
  FormControl,
  FormMessage,
  FormField,
  FormItem,
} from "@/components/ui/form";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import {
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

import { Icons } from "@/components/brand/icons";

import { useUser } from "@/components/providers/user-provider";

function createFlock(values, invite) {
  return axios.post(`${process.env.NEXT_PUBLIC_REACT_APP_API}/flocks`, {
    ...values,
    invite,
  });
}

export function CreateFlockForm({ setShowNewTeamDialog }) {
  const { user, setUser } = useUser();
  const [loading, setLoading] = useState(false);
  const formSchema = z.object({
    name: z.string().min(1, "Name cannot be blank."),
    focus: z.string().min(1, "The flock focus must be selected."),
    first_name: z.string().optional(),
    last_name: z.string().optional(),
    email: z.string().email().or(z.literal("")).optional(),
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
    ...rest
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: `${user.name}'s Tapestry`,
      focus: "",
      first_name: "",
      last_name: "",
      email: "",
    },
  });

  async function onSubmit(values, config = { invite: true }) {
    setLoading(true);
    if (values.focus === "other" && !values.first_name) {
      setError("first_name", {
        type: "manual",
        message: "First name is required when focus is 'other'.",
      });
    }

    if (values.focus === "other" && !values.last_name) {
      setError("last_name", {
        type: "manual",
        message: "Last name is required when focus is 'other'.",
      });
    }

    if (values.focus === "other" && !values.email) {
      setError("email", {
        type: "manual",
        message: "Email is required when focus is 'other'.",
      });
    }

    if (
      values.focus === "other" &&
      (!values.first_name || !values.last_name || !values.email)
    ) {
      setLoading(false);
      return;
    }

    createFlock(values, config.invite)
      .then((res) => {
        const flock = res.data;
        setUser((prev) => ({
          ...prev,
          flocks: [...prev.flocks, flock],
          active_flock_id: flock.id,
        }));

        toast.success("Tapestry created successfully.");
        setShowNewTeamDialog(false);
      })
      .catch(() => {
        setLoading(false);
        toast.error("Could not create Tapestry. Please try again.");
      });
  }

  const focusValue = watch("focus");

  return (
    <Form {...rest} control={control}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <DialogHeader>
            <DialogTitle>Create Tapestry</DialogTitle>
            <DialogDescription>
              Add a new Tapestry to create a financial partnership.
            </DialogDescription>
          </DialogHeader>
          <div>
            <FormField
              control={control}
              name="name"
              render={({ field }) => (
                <div className="flex flex-col space-y-1.5">
                  <FormItem>
                    <FormControl>
                      <>
                        <Label htmlFor="name">Name</Label>
                        <Input id="name" placeholder="Name" {...field} />
                      </>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                </div>
              )}
            />

            <FormField
              control={control}
              name="focus"
              render={({ field }) => (
                <div className="flex flex-col space-y-1.5 mt-4">
                  <Label htmlFor="focus">Who is the Tapestry for?</Label>
                  <FormItem>
                    <FormControl>
                      <Select onValueChange={field.onChange}>
                        <SelectTrigger id="focus">
                          <SelectValue placeholder="Select" />
                        </SelectTrigger>
                        <SelectContent position="popper">
                          <SelectItem value="myself">Myself</SelectItem>
                          <SelectItem value="other">Someone else</SelectItem>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                </div>
              )}
            />

            {focusValue === "other" && (
              <div className="flex flex-col">
                <FormField
                  control={control}
                  name="first_name"
                  render={({ field }) => (
                    <div className="flex flex-col space-y-1.5 mt-4">
                      <Label htmlFor="first_name">
                        What is their first name?
                      </Label>
                      <FormItem>
                        <FormControl>
                          <Input {...field} id="first_name" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    </div>
                  )}
                />

                <FormField
                  control={control}
                  name="last_name"
                  render={({ field }) => (
                    <div className="flex flex-col space-y-1.5 mt-4">
                      <Label htmlFor="last_name">
                        What is their last name?
                      </Label>
                      <FormItem>
                        <FormControl>
                          <Input {...field} id="last_name" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    </div>
                  )}
                />

                <FormField
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <div className="flex flex-col space-y-1.5 mt-4">
                      <Label htmlFor="email">What is their email?</Label>
                      <FormItem>
                        <FormControl>
                          <Input {...field} id="email" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    </div>
                  )}
                />
              </div>
            )}
          </div>
          <DialogFooter className="mt-8 flex !justify-between">
            <Button
              variant="outline"
              onClick={() => setShowNewTeamDialog(false)}
            >
              Cancel
            </Button>
            <div className="flex gap-2">
              {focus === "other" && (
                <Button
                  onClick={handleSubmit((data) =>
                    onSubmit(data, { invite: false })
                  )}
                  variant="ghost"
                  type="button"
                >
                  {loading && (
                    <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
                  )}
                  Create & Invite Later
                </Button>
              )}
              <Button type="submit">
                {loading && (
                  <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
                )}
                {focusValue === "other" ? "Create & Invite" : "Create"}
              </Button>
            </div>
          </DialogFooter>
        </>
      </form>
    </Form>
  );
}
