import { useState, useEffect } from "react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { useUser } from "@/components/providers/user-provider";
import { WelcomeStep } from "./welcome-step";
import { GetPhoneStep } from "./get-phone-step";
import { AddToHomescreenStep } from "./add-to-homescreen-step";
import { AddPhotoStep } from "./add-photo-step";
import { Icons } from "../brand/icons";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { AddTapestryAsAContactStep } from "./add-tapestry-as-a-contact-step";
import { SendFirstFileStep } from "./send-first-file-step";
import { AddFirstPersonStep } from "./add-first-person.step";
import { CongratsStep } from "./congrats-step";
import { AddDobStep } from "./add-dob-step";
import { getPeople } from "@/app/vault/api";

const steps = [1, 2, 3, 4, 5];

export function Onboarding() {
  const [people, setPeople] = useState([]);
  const { user, updateUser, updatePhoto } = useUser();
  const [skipping, setSkipping] = useState(false);

  useEffect(() => {
    getPeople({}).then((res) => {
      setPeople(res.data);
    });
  }, []);

  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  return (
    <div className="h-full w-full bg-white items-center flex flex-col justify-center gap-6 p-2 pt-4">
      {user.onboarding_step === 1 && (
        <WelcomeStep user={user} updateUser={updateUser} />
      )}
      {user.onboarding_step === 2 && (
        <AddPhotoStep
          user={user}
          updateUser={updateUser}
          updatePhoto={updatePhoto}
        />
      )}
      {user.onboarding_step === 3 && (
        <AddDobStep user={user} updateUser={updateUser} />
      )}
      {/* {user.onboarding_step === 5 && (
        <AddTapestryAsAContactStep user={user} updateUser={updateUser} />
      )}
      {user.onboarding_step === 6 && (
        <SendFirstFileStep user={user} updateUser={updateUser} />
      )} */}

      {user.onboarding_step === 4 && (
        <AddFirstPersonStep
          user={user}
          updateUser={updateUser}
          people={people}
        />
      )}
      {user.onboarding_step === 5 && (
        <CongratsStep user={user} updateUser={updateUser} />
      )}
      <div className="flex gap-2 items-center mt-1 md:mt-8">
        <Button
          size="icon"
          variant="ghost"
          disabled={user.onboarding_step === 1}
          onClick={() => {
            updateUser({ onboarding_step: user.onboarding_step - 1 });
          }}
          className="mr-4"
        >
          <ArrowLeft className="w-4 h-4 text-muted-foreground  cursor-pointer" />
        </Button>
        {steps.map((step) => (
          <div
            key={step}
            onClick={() => {
              updateUser({ onboarding_step: step });
            }}
            className={cn(
              "bg-gray-200 w-2 h-2 rounded-full cursor-pointer transform transition-transform duration-200 hover:scale-150",
              step === user.onboarding_step && "bg-brandBlue"
            )}
          />
        ))}
        <Button
          size="icon"
          variant="ghost"
          className="ml-4"
          disabled={steps.length === user.onboarding_step}
          onClick={() =>
            updateUser({ onboarding_step: user.onboarding_step + 1 })
          }
        >
          <ArrowRight className="w-4 h-4 text-muted-foreground cursor-pointer" />
        </Button>
      </div>
      <div>
        <Button
          disabled={skipping}
          onClick={() => {
            setSkipping(true);
            updateUser({ onboarding_status: "skipped" }).catch(() =>
              setSkipping(false)
            );
          }}
          className="text-gray-400"
          variant="ghost"
          size="sm"
        >
          {skipping && <Icons.spinner className="animate-spin mr-2 w-4 h-4" />}
          Skip Onboarding
        </Button>
      </div>
    </div>
  );
}
