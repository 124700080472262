import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { CircleAlert } from "lucide-react";

export function AddTapestryAsAContactStep({ updateUser }) {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <h1 className="text-2xl md:text-4xl font-semibold mt-4 text-center">
        Add Tapestry as a Contact
      </h1>
      <p className="text-muted-foreground max-w-[420px] text-center">
        Add Tapestry as a contact to easily forward attachments to your
        Tapestry.
      </p>
      <div className="p-4 border rounded-lg">
        <p className="text-muted-foreground font-semibold text-center">
          Directions
        </p>
        <ul className="mt-4">
          <li className="text-muted-foreground">
            <b>1.</b> Click the button below to add to your contacts.
          </li>
        </ul>
      </div>
      <Alert className="max-w-[373px] bg-blue-200">
        <CircleAlert className="h-4 w-4" />
        <AlertTitle>Heads up!</AlertTitle>
        <AlertDescription>
          If you are on desktop, you will have to double click the contact file
          that is downloaded when you click the button below.
        </AlertDescription>
      </Alert>
      <Button
        disabled={loading}
        onClick={() => {
          setLoading(true);
          handleDownloadVCard();
          updateUser({ onboarding_step: 6 });
        }}
      >
        Download Contact
      </Button>
    </>
  );
}

const handleDownloadVCard = () => {
  // Define the vCard data
  const vCardData = `
BEGIN:VCARD
VERSION:3.0
N:Tapestry;;;
FN:Tapestry
EMAIL;TYPE=work:upload@vault.starttapestry.com
END:VCARD
  `;
  // Create a Blob from the vCard data
  const blob = new Blob([vCardData], { type: "text/vcard" });
  const url = window.URL.createObjectURL(blob);

  // Create a link and trigger a download
  const a = document.createElement("a");
  a.href = url;
  a.download = "tapestry-contact.vcf";
  a.click();

  // Clean up the URL object
  window.URL.revokeObjectURL(url);
};
