import Link from "next/link"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Check, Undo2 } from "lucide-react"
import { Button } from "@/components/ui/button"

function determineLinkDestination(recordType) {
  switch (recordType) {
    case "Document":
      return `/vault?tab=documents`
    case "Contact":
      return `/vault?tab=contacts`
    default:
      return "/"
  }
}

export function Notification({
  notification,
  markNotificationAsRead,
  markNotificationAsUnRead,
}) {
  return (
    <Link href={determineLinkDestination(notification.record_type)}>
      <div
        key={notification.id}
        className="flex items-center gap-4 hover:bg-secondary/50 p-4 cursor-pointer"
      >
        {!notification.read_at && (
          <div>
            <span className="flex h-2 w-2 rounded-full bg-blue-600"></span>
          </div>
        )}
        <Avatar className="hidden h-9 w-9 sm:flex">
          <AvatarImage src="/avatars/01.png" alt="Avatar" />
          <AvatarFallback>RA</AvatarFallback>
        </Avatar>
        <div className="grid gap-1">
          <p className="text-sm text-muted-foreground">
            {notification.message}
          </p>
        </div>
        <div className="ml-auto">
          {!notification.read_at ? (
            <Button
              onClick={(e) => {
                e.preventDefault()
                markNotificationAsRead(notification.id)
              }}
              variant="ghost"
            >
              <Check className="h-4 w-4" />
            </Button>
          ) : (
            <Button
              onClick={(e) => {
                e.preventDefault()
                markNotificationAsUnRead(notification.id)
              }}
              variant="ghost"
            >
              <Undo2 className="h-4 w-4" />
            </Button>
          )}
        </div>
      </div>
    </Link>
  )
}
