import { useEffect, useState } from "react";

import { Bell } from "lucide-react";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { useUser } from "@/components/providers/user-provider";
import { cn } from "@/lib/utils";
import axios from "@/axios";
import { Notification } from "./notification";
import { LoadingNotification } from "./loading-notification";

export function Notifications({ navCollapsed }) {
  const { user } = useUser();
  const [reloadCount, setReloadCount] = useState(0);
  const [loadingNotifications, setLoadingNotifications] = useState(true);
  const [selectedTab, setSelectedTab] = useState("unread");
  const [notifications, setNotifications] = useState([]);

  function reload() {
    setReloadCount((prev) => prev + 1);
  }

  function markNotificationAsRead(notificationId) {
    return axios
      .put(
        `${process.env.NEXT_PUBLIC_REACT_APP_API}/notifications/${notificationId}`,
        { read_at: true }
      )
      .then(() => reload());
  }

  function markNotificationAsUnRead(notificationId) {
    return axios
      .put(
        `${process.env.NEXT_PUBLIC_REACT_APP_API}/notifications/${notificationId}`,
        { read_at: false }
      )
      .then(() => reload());
  }

  function getNotifications({ notificationStatus }) {
    return axios
      .get(
        `${process.env.NEXT_PUBLIC_REACT_APP_API}/notifications?notification_status=${notificationStatus}`
      )
      .then((response) => {
        setNotifications(response.data);
      });
  }

  useEffect(() => {
    setLoadingNotifications(true);
    getNotifications({ notificationStatus: selectedTab }).finally(() => {
      setLoadingNotifications(false);
    });
  }, [selectedTab, reloadCount]);

  return (
    <Popover>
      <PopoverTrigger
        variant="ghost"
        className={cn(
          "h-9 flex items-center justify-center hover:bg-white/[.2] rounded-md gap-2",
          navCollapsed ? "justify-center w-9" : "justify-start px-2.5"
        )}
      >
        <Bell className="h-4 w-4 text-[#9a9a9a]" />
        <span
          className={
            navCollapsed
              ? "sr-only"
              : "text-[#cecfd0] fade-up text-sm font-medium"
          }
        >
          Notifications
        </span>
      </PopoverTrigger>
      <PopoverContent className="w-[360px] sm:w-[400px] p-0" align="start">
        <Tabs value={selectedTab} className="w-[360px] sm:w-[400px]">
          <TabsList className="m-4">
            <TabsTrigger
              onClick={() => setSelectedTab("unread")}
              value="unread"
            >
              Unread
            </TabsTrigger>
            <TabsTrigger onClick={() => setSelectedTab("read")} value="read">
              Read
            </TabsTrigger>
          </TabsList>
          <Separator />
          <TabsContent value="unread">
            {loadingNotifications ? (
              <LoadingNotification />
            ) : (
              <>
                {notifications.length === 0 && (
                  <div className="flex items-center gap-4 p-4">
                    No notifications
                  </div>
                )}
                {notifications.map((notification) => (
                  <Notification
                    key={notification.id}
                    notification={notification}
                    markNotificationAsRead={markNotificationAsRead}
                    markNotificationAsUnRead={markNotificationAsUnRead}
                  />
                ))}
                <Separator />
              </>
            )}
          </TabsContent>
          <TabsContent value="read">
            {loadingNotifications ? (
              <LoadingNotification />
            ) : (
              <>
                {notifications.length === 0 && (
                  <div className="flex items-center gap-4 p-4 ">
                    No notifications
                  </div>
                )}
                {notifications.map((notification) => (
                  <Notification
                    markNotificationAsUnRead={markNotificationAsUnRead}
                    markNotificationAsRead={markNotificationAsRead}
                    key={notification.id}
                    notification={notification}
                  />
                ))}
                <Separator />
              </>
            )}
          </TabsContent>
        </Tabs>
      </PopoverContent>
    </Popover>
  );
}
