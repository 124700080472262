import { useState } from "react";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/brand/icons";

export function AddDobStep({ user, updateUser }) {
  const [birthday, setBirthday] = useState(user.date_of_birth);
  const [loading, setLoading] = useState(false);

  function handleAddBirthday() {
    setLoading(true);

    updateUser({ onboarding_step: 4, date_of_birth: birthday }).catch(() => {
      setLoading(false);
    });
  }
  return (
    <>
      <h1 className="text-2xl md:text-4xl font-semibold mt-32 md:mt-4 text-center">
        🎂 Add your Birthday.
      </h1>
      <p className="text-muted-foreground max-w-[420px] text-center">
        We use your birthday to help with your retirement planning.
      </p>
      <div className="p-4 border rounded-lg flex gap-4 flex-col max-w-[420px] w-full">
        <div className="flex justify-between w-full flex-wrap gap-4">
          <div className="flex flex-col gap-2 w-full">
            <Label htmlFor="dob">Birthday</Label>
            <Input
              type="date"
              id="dob"
              onChange={(e) => setBirthday(e.target.value)}
              value={birthday}
              className="w-full"
            />
          </div>
        </div>
        <Button onClick={handleAddBirthday} disabled={loading}>
          {loading && <Icons.spinner className="animate-spin mr-2 w-4 h-4" />}
          Save
        </Button>
      </div>
    </>
  );
}
