"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";
import { ReactSVG } from "react-svg";

import { cn, featureEnabled } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useUser } from "../providers/user-provider";
import { DASHBOARD } from "@/config/features";

const links = [
  {
    title: "Dashboard",
    label: "128",
    icon: "home-line",
    variant: "ghost",
    route: "/",
  },
  {
    title: "Vault",
    label: "128",
    icon: "safe",
    variant: "ghost",
    route: "/vault",
  },
  {
    title: "Stories",
    label: "128",
    icon: "book-marked",
    variant: "ghost",
    route: "/stories",
  },
  {
    title: "Plan",
    icon: "bar-chart-square-03",
    variant: "ghost",
    route: "/plan",
  },

  {
    title: "Protect",
    label: "128",
    icon: "shield-dollar",
    variant: "ghost",
    route: "/protect",
  },

  {
    title: "Tapestry",
    label: "128",
    icon: "users-01",
    variant: "ghost",
    route: "/tapestry",
  },
];

export function Nav({ navCollapsed, className = "", closeMobileNav, mobile }) {
  const pathname = usePathname();
  const { user } = useUser();
  return (
    <div
      data-collapsed={navCollapsed}
      className={cn(
        "flex flex-col py-2 data-[collapsed=true]:py-2 mt-8 mx-2",
        className
      )}
    >
      <nav className="flex flex-col gap-4 pl-[5px]">
        {links
          .filter((link) => {
            if (featureEnabled(DASHBOARD, user)) {
              return true;
            } else {
              return link.title !== "Dashboard";
            }
          })
          .map((link, index) => {
            return (
              <Tooltip key={index} delayDuration={0}>
                <TooltipTrigger asChild>
                  <Link
                    onClick={() => {
                      mobile && closeMobileNav();
                    }}
                    href={link.route}
                    className={cn(
                      buttonVariants({
                        variant: link.variant,
                        size: navCollapsed ? "icon" : "md",
                      }),
                      navCollapsed ? "h-9 w-9" : "h-9",
                      pathname === link.route
                        ? "bg-white/[.3] hover:bg-white/[.2]"
                        : "hover:bg-white/[.2]",
                      !navCollapsed &&
                        "flex items-center justify-start gap-2 px-2"
                    )}
                  >
                    <ReactSVG
                      src={`/icons/${link.icon}.svg`}
                      beforeInjection={(svg) => {
                        const paths = svg.querySelectorAll("path");
                        paths.forEach((path) => {
                          path.setAttribute("stroke", "white");
                        });
                        svg.setAttribute("width", 20);
                        svg.setAttribute("height", 20);
                      }}
                    />

                    <span
                      className={
                        navCollapsed
                          ? "sr-only"
                          : "text-white opacity-80 fade-up"
                      }
                    >
                      {link.title}
                    </span>
                    {/* {!navCollapsed && <span className="">{link.title}</span>} */}
                  </Link>
                </TooltipTrigger>
                <TooltipContent
                  side="right"
                  className="flex items-center gap-4"
                >
                  {link.title}
                </TooltipContent>
              </Tooltip>
            );
          })}
      </nav>
    </div>
  );
}
