"use client";

import React, { useState } from "react";
import Image from "next/image";
import { usePathname } from "next/navigation";

import { cn } from "@/lib/utils";
import { Logo } from "@/components/brand";
import { Button } from "@/components/ui/button";
import { Nav } from "@/components/navigation/nav";
import { UserProvider } from "@/components/providers";
import { FlockCreator } from "@/components//authentication/flock-creator";
import { useUser } from "../providers/user-provider";
import { UserNav } from "../navigation/user-nav";
import { Notifications } from "../navigation/notifications";
import { FlockSwitcher } from "../navigation/flocks";
import { TopNav } from "../navigation/top-nav";
import { MessageCircleQuestion } from "lucide-react";
import { NavProvider, useNav } from "../providers/nav-provider";
import { Onboarding } from "@/components/onboarding";

export function Authenticated({ children }) {
  const pathname = usePathname();

  if (
    pathname === "/login" ||
    pathname === "/signup" ||
    pathname === "/forgot-password" ||
    pathname === "/reset-password" ||
    pathname === "/invitation" ||
    pathname === "/otp" ||
    pathname === "/terms" ||
    pathname === "/privacy" ||
    pathname === "/card-game" ||
    pathname === "/shop" ||
    pathname.includes("write")
  ) {
    return <>{children}</>;
  }
  return (
    <UserProvider>
      <NavProvider>
        <FlowChooser>{children}</FlowChooser>
      </NavProvider>
    </UserProvider>
  );
}

function FlowChooser({ children }) {
  const { user } = useUser();
  const { navCollapsed, setNavCollapsed } = useNav();

  const [intercomOpen, setIntercomOpen] = useState(false);

  if (user.flocks.length === 0) {
    return <FlockCreator />;
  }

  window.Intercom("onShow", function () {
    setIntercomOpen(true);
  });

  window.Intercom("onHide", function () {
    setIntercomOpen(false);
  });

  if (user.onboarding_status === "in_progress") return <Onboarding />;

  return (
    <div className="flex flex-row h-full items-stretch">
      <div className="relative flex flex-col">
        <div
          className={cn(
            `fixed z-50 top-0 bottom-0 transition-all duration-300 ease-in-out hidden md:block`
          )}
        >
          <div className="flex flex-col justify-between pb-4 h-full">
            <div>
              <div
                className={cn(
                  "flex h-[52px] items-center h-[52px] mx-2 pl-[5px]"
                )}
              >
                <Logo />
              </div>
              <div className={`flex mt-4 mx-2 pl-[5px]`}>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => {
                    window.localStorage.setItem("navCollapsed", !navCollapsed);
                    setNavCollapsed(!navCollapsed);
                  }}
                  className="hover:bg-white/[.2]"
                >
                  <Image
                    alt="drawer icon"
                    width={16}
                    height={16}
                    src="/icons/drawer.svg"
                  />
                </Button>
              </div>
              <div className="mx-2 pl-[5px] mt-2">
                <FlockSwitcher isCollapsed={navCollapsed} />
              </div>
            </div>

            <Nav navCollapsed={navCollapsed} />

            <div className={`flex mt-4 mx-2 pl-[5px] flex-col gap-4`}>
              <Button
                variant="ghost"
                size={navCollapsed ? "icon" : "sm"}
                className={cn(
                  "flex items-center justify-center hover:bg-white/[.2] rounded-md",
                  intercomOpen && "bg-[#e1b649]",
                  navCollapsed
                    ? "h-9 w-9"
                    : "h-9 flex items-center justify-start gap-2 px-2"
                )}
                onClick={() => {
                  intercomOpen
                    ? window.Intercom("hide")
                    : window.Intercom("show");
                }}
              >
                <MessageCircleQuestion
                  className={cn(
                    "h-5 w-5 text-[#9a9a9a]",
                    intercomOpen ? "text-white" : "text-[#9a9a9a]"
                  )}
                />

                <span
                  className={
                    navCollapsed ? "sr-only" : "text-[#cecfd0] fade-up text-sm"
                  }
                >
                  Need help?
                </span>
              </Button>
              <Notifications navCollapsed={navCollapsed} />
              <UserNav navCollapsed={navCollapsed} />
            </div>
          </div>
        </div>
      </div>

      <div
        className={cn(
          "transition-all relative w-full flex min-h-screen flex-col duration-300 ease-in-out",
          navCollapsed
            ? "md:left-16 md:w-[calc(100%-4rem)]"
            : "md:left-[14rem] md:w-[calc(100%-14rem)]"
        )}
      >
        <TopNav intercomOpen={intercomOpen} />
        <main className="flex-1">{children}</main>
      </div>
    </div>
  );
}
