import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import Image from "next/image";

export function GooseLogo({ height = 96, width = 256, className = "mt-6" }) {
  return (
    <>
      <span className={className}>
        <Image
          src="/logo.png"
          height={height}
          width={width}
          alt="logo symbol"
        />
      </span>
    </>
  );
}
