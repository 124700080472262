import { useState } from "react";
import Image from "next/image";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/brand/icons";
import { Share } from "lucide-react";

export function AddToHomescreenStep({ updateUser }) {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Image
        className="rounded-lg"
        src="/images/add-to-homescreen.jpeg"
        width="200"
        height="200"
      />
      <h1 className="text-2xl md:text-4xl font-semibold mt-4 text-center">
        Add Tapestry to your Home Screen
      </h1>
      {/* <p className="text-muted-foreground max-w-[420px] text-center text-sm">
        Add Tapestry to your home screen for easy access to our web platform.
      </p> */}
      <div className="p-4 border rounded-lg">
        <p className="text-muted-foreground font-semibold text-center">
          Directions
        </p>
        <ul className="mt-4 text-muted-foreground">
          <li>
            <b>1.</b> Tap this icon.
            <Share className="inline w-4 h-4 mb-1" />
          </li>
          <li>
            <b>2.</b> Select Add to Home Screen. (You may have to scroll down to
            find it.)
          </li>
          <li>
            <b>3.</b> Type Tapestry into the name field
          </li>
          <li>
            <b>4.</b> Click add in the top right to complete
          </li>
        </ul>
      </div>
      <Button
        disabled={loading}
        onClick={() => {
          setLoading(true);
          updateUser({ onboarding_step: 4 }).catch(() => setLoading(false));
        }}
      >
        {loading && <Icons.spinner className="animate-spin mr-2" />}
        I've added Tapestry to my home screen
      </Button>
    </>
  );
}
