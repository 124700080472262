import { useState } from "react";
import Image from "next/image";
import HeroVideoDialog from "@/components/ui/hero-video-dialog";
import { Button } from "@/components/ui/button";
import { Icons } from "../brand/icons";

export function WelcomeStep({ updateUser }) {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <HeroVideoDialog
        className="dark:hidden block w-[400px]"
        animationStyle="top-in-bottom-out"
        videoSrc="https://www.loom.com/embed/b10686e256f64fd280244806fa1cc8c7?sid=57239b99-b7be-431b-ad80-360d8a5abf14"
        thumbnailSrc="https://cdn.loom.com/sessions/thumbnails/b10686e256f64fd280244806fa1cc8c7-ba3cbb34b62e2045-full-play.gif"
        thumbnailAlt="Hero Video"
      />
      <h1 className="text-2xl md:text-5xl font-semibold mt-4 text-center">
        👋 Welcome to Tapestry
      </h1>

      <p className="text-muted-foreground max-w-[420px] text-center">
        Tapestry is a collaborative financial coach. Watch the video above for a
        walkthrough of what to expect during our onboarding experience.
      </p>
      <Button
        disabled={loading}
        onClick={() => {
          setLoading(true);
          updateUser({ onboarding_step: 2 }).catch(() => setLoading(false));
        }}
        size="lg"
      >
        {loading && <Icons.spinner className="animate-spin mr-2" />}
        Get Started
      </Button>
    </>
  );
}
