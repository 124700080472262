import { useState } from "react";
import Image from "next/image";
import { Button } from "@/components/ui/button";
import { Icons } from "../brand/icons";
import { ConfettiSideCannons } from "../ui/confetti-side-canons";

export function CongratsStep({ updateUser }) {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <div className="bg-brandBlue p-4 rounded-xl">
        <Image alt="Tapestry logo" src="/logo.svg" width="75" height="75" />
      </div>
      <h1 className="text-4xl font-semibold mt-4 text-center">
        🎉 Onboarding Complete!
      </h1>
      <p className="text-muted-foreground max-w-[420px] text-center">
        Congratulations! You've completed the onboarding process. You're now
        ready to start using Tapestry.
      </p>
      <Button
        disabled={loading}
        onClick={() => {
          setLoading(true);
          updateUser({ onboarding_status: "completed" }).catch(() =>
            setLoading(false)
          );
        }}
        size="lg"
      >
        {loading && <Icons.spinner className="animate-spin mr-2" />}
        Finish
      </Button>
      <ConfettiSideCannons />
    </>
  );
}
