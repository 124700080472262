import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import Image from "next/image";

export function Logo(props) {
  return (
    <>
      <span className={cn("mt-6")} {...props}>
        <Button className="hover:bg-white/[.06]" size="icon" variant="ghost">
          <Image
            src="/logo.svg"
            height={24}
            width={24}
            alt="logo symbol"
            className="hover:animate__headShake"
          />
        </Button>
      </span>
    </>
  );
}
