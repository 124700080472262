import { useState, useEffect } from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import { Icons } from "../brand/icons";
import axios from "@/axios";
import { useUser } from "../providers/user-provider";

function createPerson({ first_name, last_name, email, invite, inner_circle }) {
  return axios.post(`${process.env.NEXT_PUBLIC_REACT_APP_API}/people`, {
    first_name,
    last_name,
    email,
    invite,
    inner_circle,
  });
}

export function AddFirstPersonStep({ people }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [invitation, setInvitation] = useState(false);
  const [innerCircle, setInnerCircle] = useState(true);
  const { updateUser } = useUser();

  // useEffect(() => {
  //   if (people.length > 1) {
  //     updateUser({ onboarding_step: 5 });
  //   }
  // }, []);

  function handleCreatePerson() {
    setLoading(true);
    createPerson({
      first_name: firstName,
      last_name: lastName,
      email,
      invite: invitation,
      inner_circle: innerCircle,
    })
      .then((res) => {
        updateUser({ onboarding_step: 5 });
      })
      .catch(() => {
        setLoading(false);
      });
  }
  return (
    <>
      <h1 className="text-2xl md:text-4xl font-semibold mt-32 md:mt-4 text-center">
        Add your first person
      </h1>
      <p className="text-muted-foreground max-w-[420px] text-center">
        The best plans are built together. Add a spouse, partner, or family
        member to create a shared vision for the future.
      </p>
      <div className="p-4 border rounded-lg flex gap-4 flex-col max-w-[420px] w-full">
        <div className="flex justify-between w-full flex-wrap gap-4">
          <div className="flex flex-col gap-2 w-full">
            <Label htmlFor="first_name">First Name</Label>
            <Input
              id="first_name"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              className="w-full"
            />
          </div>
          <div className="flex flex-col gap-2 w-full">
            <Label htmlFor="last_name">Last Name</Label>
            <Input
              id="last_name"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor="email">Email</Label>
          <Input
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </div>
        {/* <div className="flex items-center space-x-2">
          <Checkbox
            checked={innerCircle}
            onCheckedChange={(value) => setInnerCircle(value)}
            id="inner_circle"
          />
          <label
            htmlFor="inner_circle"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Is this person in your inner circle?
          </label>
        </div> */}
        <div className="flex items-center space-x-2">
          <Checkbox
            checked={invitation}
            onCheckedChange={(value) => setInvitation(value)}
            id="invite"
          />
          <label
            htmlFor="invite"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Send an invite to this person
          </label>
        </div>
      </div>
      <Button
        onClick={handleCreatePerson}
        disabled={loading || (invitation && !email)}
      >
        {loading && <Icons.spinner className="animate-spin mr-2 w-4 h-4" />}
        Add person to Tapestry
      </Button>
    </>
  );
}
