import { GooseLogo } from "@/components/brand";

export function AuthBillboard({ children }) {
  return (
    <>
      <div className="relative h-full flex-col items-center justify-center lg:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
        <div className="relative hidden h-full flex-col bg-muted md:p-10 text-white lg:flex dark:border-r">
          <div className="absolute inset-0 bg-brandGreen" />
          <div className="relative z-20 flex items-center text-lg font-medium">
            <GooseLogo />
          </div>
          <div className="relative z-20 mt-auto">
            <blockquote className="space-y-2">
              <p className="text-lg">
                &ldquo;Tapestry not only helped me manage my parent's finances.
                It helped prepare me for my retirement. Where would I be if I
                didn't start Tapestry.&rdquo;
              </p>
              <footer className="text-sm">Sofia Davis</footer>
            </blockquote>
          </div>
        </div>
        {children}
      </div>
    </>
  );
}
