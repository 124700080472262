"use client"

import * as React from "react"
import { FlockSwitcher } from "@/components/navigation/flocks"

export function MainNav() {
  return (
    <div className="mr-4 md:flex">
      <FlockSwitcher />
    </div>
  )
}
