import { createContext, useContext, useEffect, useMemo, useState } from "react";

const NavContext = createContext();

export const NavProvider = ({ children, ...props }) => {
  const [navCollapsed, setNavCollapsed] = useState(
    localStorage.getItem("navCollapsed") === "true"
  );

  return (
    <NavContext.Provider value={{ navCollapsed, setNavCollapsed }} {...props}>
      {children}
    </NavContext.Provider>
  );
};

export const useNav = () => {
  const context = useContext(NavContext);

  if (!context) {
    throw new Error(
      'The "useNav" hook can only be used within a "NavProvider".'
    );
  }
  return context;
};
