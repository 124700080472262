import { useState, useEffect } from "react";
import Image from "next/image";
import { Button } from "@/components/ui/button";
import QRCode from "react-qr-code";
import { Icons } from "../brand/icons";
import { Separator } from "@/components/ui/separator";

export function GetPhoneStep({ updateUser }) {
  const [loading, setLoading] = useState(false);
  const [rerouting, setRerouting] = useState(true);

  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  useEffect(() => {
    if (isMobileDevice()) {
      // Call your function here if the user is on a mobile device
      handleMobileDevice();
    } else {
      setRerouting(false);
    }
  }, []);

  const handleMobileDevice = () => {
    updateUser({ onboarding_step: 3 });
  };

  if (rerouting) {
    return <Icons.spinner className="animate-spin w-8 h-8" />;
  }

  return (
    <>
      <div className="rounded-lg">
        <QRCode size={92} value={`${process.env.NEXT_PUBLIC_REACT_APP_URL}`} />
      </div>
      <h1 className="text-2xl md:text-4xl font-semibold mt-4 text-center">
        📱 Login with your Mobile Device
      </h1>
      <p className="text-muted-foreground max-w-[420px] text-center text-sm">
        Logging into Tapestry on mobile is a key first step in order to
        effortlessly organize your life.
      </p>

      <div className="p-4 border rounded-lg">
        <p className="text-muted-foreground font-semibold text-center">
          Directions
        </p>
        <ul className="mt-4">
          <li className="text-muted-foreground">
            <b>1.</b> Open the camera app on your phone
          </li>
          <li className="text-muted-foreground">
            <b>2.</b> Scan the QR code
          </li>
        </ul>
        <div className="my-4 flex items-center text-[0.9375rem] uppercase tracking-[0.1em] w-full max-w-[300px]">
          <div className="flex-grow h-px bg-gray-300 mr-4"></div>
          <span className="text-muted-foreground">OR</span>
          <div className="flex-grow h-px bg-gray-300 ml-4"></div>
        </div>
        <ul className="">
          <li className="text-muted-foreground">
            <b>1.</b> Open your browser on your phone
          </li>
          <li className="text-muted-foreground">
            <b>2.</b> Navigate to{" "}
            <a
              className="underline"
              href={process.env.NEXT_PUBLIC_REACT_APP_URL}
            >
              {process.env.NEXT_PUBLIC_REACT_APP_URL}
            </a>
          </li>
        </ul>
      </div>
      <Button
        disabled={loading}
        onClick={() => {
          setLoading(true);
          updateUser({ onboarding_step: 3 }).catch(() => setLoading(false));
        }}
      >
        {loading && <Icons.spinner className="animate-spin mr-2" />}
        I'm logged in on mobile
      </Button>
    </>
  );
}
