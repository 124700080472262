"use client";

import * as React from "react";
import Link from "next/link";
import { useRouter } from "next/navigation";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Sheet, DarkSheetContent, SheetTrigger } from "@/components/ui/sheet";
import { MessageCircleQuestion } from "lucide-react";
import Image from "next/image";
import { Notifications } from "./notifications";
import { FlockSwitcher } from "./flocks";
import { Nav } from "./nav";
import { UserNav } from "./user-nav";

export function MobileNav({ intercomOpen }) {
  const [open, setOpen] = React.useState(false);

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button
          variant="ghost"
          className="px-0 text-base hover:bg-transparent focus-visible:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 md:hidden text-white"
        >
          <svg
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
          >
            <path
              d="M3 5H11"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M3 12H16"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M3 19H21"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
          <span className="sr-only">Toggle Menu</span>
        </Button>
      </SheetTrigger>
      <DarkSheetContent
        side="left"
        className="pr-0 bg-[#1e262b] border-r-[#1e262b]"
      >
        <Image className="mr-4 mt-0" src="/logo.png" width={200} height={30} />

        <div className="my-4 h-[calc(100vh-12rem)] pb-4 pl-2 flex flex-col justify-between">
          <FlockSwitcher isCollapsed={false} className="mr-4" />
          <Nav
            navCollapsed={false}
            className="mt-0"
            closeMobileNav={() => setOpen(false)}
            mobile
          />

          <div className={`flex mt-4 mx-2 pl-[5px] flex-col gap-4`}>
            <Button
              variant="ghost"
              size="sm"
              className={cn(
                "flex items-center justify-center hover:bg-white/[.2] rounded-md",
                intercomOpen && "bg-[#e1b649]",
                "h-9 flex items-center justify-start gap-2 px-2"
              )}
              onClick={() => {
                intercomOpen
                  ? window.Intercom("hide")
                  : window.Intercom("show");
              }}
            >
              <MessageCircleQuestion
                className={cn(
                  "h-5 w-5 text-[#9a9a9a]",
                  intercomOpen ? "text-white" : "text-[#9a9a9a]"
                )}
              />

              <span className={"text-[#cecfd0] fade-up text-sm"}>
                Need help?
              </span>
            </Button>
            <Notifications navCollapsed={false} />
            <UserNav navCollapsed={false} />
          </div>
        </div>
      </DarkSheetContent>
    </Sheet>
  );
}

function MobileLink({ href, onOpenChange, className, children, ...props }) {
  const router = useRouter();
  return (
    <Link
      href={href}
      onClick={() => {
        router.push(href.toString());
        onOpenChange?.(false);
      }}
      className={cn(className)}
      {...props}
    >
      {children}
    </Link>
  );
}
