import Link from "next/link";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuGroup,
} from "@/components/ui/dropdown-menu";
import { useUser } from "../providers/user-provider";
import { CircleUserRound } from "lucide-react";
import { cn } from "@/lib/utils";

export function UserNav({ navCollapsed }) {
  const { logout, user } = useUser();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size={navCollapsed ? "icon" : "sm"}
          className={cn(
            "hover:bg-white/[.2]",
            navCollapsed ? "" : "flex items-center justify-start gap-2 px-2"
          )}
        >
          <CircleUserRound className="w-5 h-5 text-[#9a9a9a]" />
          {/* <Avatar className="h-8 w-8">
            <AvatarImage
              className="object-cover"
              src={user.avatar_url}
              alt="profile image"
            />
            <AvatarFallback>
              {user.first_name ? user.first_name[0] : ""}
              {user.last_name ? user.last_name[0] : ""}
            </AvatarFallback>
          </Avatar> */}
          <span
            className={
              navCollapsed ? "sr-only" : "text-[#cecfd0] fade-up text-sm"
            }
          >
            User Settings
          </span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">
              {user.first_name} {user.last_name}
            </p>
            <p className="text-xs leading-none text-muted-foreground">
              {user.email}
            </p>
          </div>
        </DropdownMenuLabel>
        {/* <DropdownMenuSeparator /> */}
        <DropdownMenuGroup>
          <Link href="/settings">
            <DropdownMenuItem>Settings</DropdownMenuItem>
          </Link>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={logout}>Log out</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
