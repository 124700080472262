"use client";

import { useState, useEffect } from "react";
import {
  CaretSortIcon,
  CheckIcon,
  PlusCircledIcon,
} from "@radix-ui/react-icons";

import { cn } from "@/lib/utils";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { toast } from "sonner";
import { getFlocks } from "./api";
import { Skeleton } from "@/components/ui/skeleton";
import { CreateFlockForm } from "./create-flock-form";
import { useUser } from "@/components/providers/user-provider";

export function FlockSwitcher({ className, isCollapsed }) {
  const { user, updateUser } = useUser();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [flocks, setFlocks] = useState([]);
  const [showNewTeamDialog, setShowNewTeamDialog] = useState(false);
  const [selectedFlock, setSelectedFlock] = useState();

  useEffect(() => {
    setLoading(true);
    getFlocks()
      .then((res) => {
        setFlocks(res.data);
        setSelectedFlock(
          res.data.find((flock) => flock.id === user.active_flock_id)
        );
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [user.active_flock_id]);

  if (loading) {
    return (
      <Button
        className="justify-between gap-2 fade-up"
        variant="outline"
        disabled
      >
        <Skeleton className="h-4 w-4" />
        <Skeleton className="h-4 w-[140px]" />
      </Button>
    );
  }

  function updateActiveFlock(flockId) {
    return updateUser({ active_flock_id: flockId });
  }

  return (
    <Dialog open={showNewTeamDialog} onOpenChange={setShowNewTeamDialog}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          {isCollapsed ? (
            <Button
              className="hover:bg-white/[.2] h-9 w-9"
              variant="ghost"
              size="icon"
            >
              <Avatar className="h-5 w-5">
                <AvatarImage
                  src={`https://avatar.vercel.sh/${selectedFlock?.id}.png`}
                  alt={selectedFlock?.name}
                  className="grayscale"
                />
                <AvatarFallback>SC</AvatarFallback>
              </Avatar>
            </Button>
          ) : (
            <Button
              variant="outline"
              role="combobox"
              aria-expanded={open}
              aria-label="Select a flock"
              className={cn(
                "justify-between bg-[3f3f3f] hover:bg-white/[.2] border border-[#494949] fade-up",
                className
              )}
            >
              {selectedFlock ? (
                <>
                  <Avatar className="mr-2 h-5 w-5">
                    <AvatarImage
                      src={`https://avatar.vercel.sh/${selectedFlock.id}.png`}
                      alt={selectedFlock.name}
                      className="grayscale"
                    />
                    <AvatarFallback>SC</AvatarFallback>
                  </Avatar>
                  <span className="max-w-[120px] sm:max-[120px] truncate text-white font-medium">
                    {selectedFlock.name}
                  </span>
                  <CaretSortIcon className="ml-auto h-4 w-4 shrink-0 opacity-50 text-[#9f9f9f]" />
                </>
              ) : (
                <>
                  <Avatar className="mr-2 h-5 w-5">
                    <AvatarImage
                      src={`https://avatar.vercel.sh/1.png`}
                      className="grayscale"
                    />
                    <AvatarFallback>SC</AvatarFallback>
                  </Avatar>
                  {"No Tapestries Created"}
                  <CaretSortIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
                </>
              )}
            </Button>
          )}
        </PopoverTrigger>
        <PopoverContent className="p-0" align="start">
          <Command>
            <CommandInput placeholder="Search Tapestries..." />
            <CommandList>
              <CommandEmpty>No Tapestry found.</CommandEmpty>

              <CommandGroup heading="Your Tapestries">
                {flocks.map((flock) => (
                  <CommandItem
                    key={flock.id}
                    onSelect={() => {
                      updateActiveFlock(flock.id)
                        .then((res) => {
                          setSelectedFlock(flock);
                        })
                        .catch((err) => {
                          toast.error("Failed to update active flock");
                        });

                      setOpen(false);
                    }}
                    className="text-sm"
                  >
                    <Avatar className="mr-2 h-5 w-5">
                      <AvatarImage
                        src={`https://avatar.vercel.sh/${flock.id}.png`}
                        alt={flock.label}
                        className="grayscale"
                      />
                      <AvatarFallback>SC</AvatarFallback>
                    </Avatar>
                    {flock.name}
                    <CheckIcon
                      className={cn(
                        "ml-auto h-4 w-4",
                        selectedFlock?.id === flock.id
                          ? "opacity-100"
                          : "opacity-0"
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
            <CommandSeparator />
            <CommandList>
              <CommandGroup>
                <DialogTrigger asChild>
                  <CommandItem
                    onSelect={() => {
                      setOpen(false);
                      setShowNewTeamDialog(true);
                    }}
                  >
                    <PlusCircledIcon className="mr-2 h-5 w-5" />
                    Create Tapestry
                  </CommandItem>
                </DialogTrigger>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      <DialogContent>
        <CreateFlockForm setShowNewTeamDialog={setShowNewTeamDialog} />
      </DialogContent>
    </Dialog>
  );
}
