import { useState, useRef, useEffect } from "react";
import { cn } from "@/lib/utils";
import { Camera } from "lucide-react";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/brand/icons";
import { toast } from "sonner";

export function AddPhotoStep({ updateUser, user, updatePhoto }) {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();

  useEffect(() => {
    setPreview(user?.avatar_url);
  }, [user]);

  const handleFileUpload = (event) => {
    const temp_file = event.target.files[0];
    setImage(temp_file);

    if (temp_file) {
      setPreview(URL.createObjectURL(temp_file));
    } else {
      setPreview(null);
    }
  };

  const handleSubmit = async () => {
    if (!image) return;

    const formData = new FormData();
    formData.append("avatar", image);

    try {
      setLoading(true);
      await updatePhoto(formData);
      updateUser({ onboarding_step: 3 });
    } catch (error) {
      toast.error("Error Uploading image. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex items-center justify-center">
        <div onClick={() => {}} className="relative group h-32 w-32 rounded-lg">
          <Input
            className="hidden"
            id="picture"
            type="file"
            accept=".jpg, .jpeg, .png"
            onChange={handleFileUpload}
          />
          <label htmlFor="picture">
            <Avatar className={cn("h-32 w-32")}>
              <AvatarImage className="object-cover" src={preview} />
              <AvatarFallback>Add Photo</AvatarFallback>
            </Avatar>
            <div className="rounded-full cursor-pointer absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <Camera className="text-[#cfcfcf] w-16 h-16" />
            </div>
          </label>
        </div>
      </div>
      <h1 className="text-2xl md:text-4xl font-semibold mt-4 text-center">
        📷 Add a profile picture
      </h1>
      <p className="text-muted-foreground max-w-[420px] text-center text-sm">
        Photos are a great reminder of why we're planning.
      </p>

      <div className="p-4 border rounded-lg">
        <p className="text-muted-foreground font-semibold text-center">
          Directions
        </p>
        <ul className="mt-4">
          <li className="text-muted-foreground">
            <b>1.</b> Click the add photo button above.
          </li>
          <li className="text-muted-foreground">
            <b>2.</b> Select your favorite picture of yourself
          </li>
        </ul>
      </div>

      <Button
        disabled={loading || !image}
        onClick={() => {
          handleSubmit();
        }}
        size="lg"
      >
        {loading && <Icons.spinner className="animate-spin mr-2" />}
        Save
      </Button>
    </>
  );
}
