import { MainNav } from "@/components/navigation/main-nav";
import { MobileNav } from "@/components/navigation/mobile-nav";
import { UserNav } from "@/components/navigation/user-nav";
import { Notifications } from "./notifications";
import Image from "next/image";

export function TopNav({ intercomOpen }) {
  return (
    <header className="md:hidden w-full border-b border-border/40 bg-[#1e262b]">
      <div className="container flex h-14 max-w-full items-center mx-0 justify-between">
        <MobileNav intercomOpen={intercomOpen} />
        <Image src="/logo.svg" height={24} width={24} alt="logo symbol" />
        <div />
      </div>
    </header>
  );
}
